import {
  faBook,
  faFingerprint,
  faHeading,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link } from "gatsby"
import { debounce, filter, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { Col, Container, Input, Row } from "reactstrap"
import Dropdown from "../components/dropdown"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function FormsDirctory(props) {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchText, _setSearchText] = useState("")

  const setSearchText = debounce(value => {
    console.log(value)
    _setSearchText(value)
    filterData(value)
  }, 350)

  const getHighlightedText = text => {
    var reg = new RegExp(searchText, "i")

    if (isEmpty(searchText)) return text

    let temp = text
      .toString()
      .replace(reg, `<span class='highlighted-text'>${searchText}</span>`)

    return <span dangerouslySetInnerHTML={{ __html: temp }} />
  }

  const hadleInput = ({ target: { value = "" } }) => {
    setSearchText(value)
  }

  useEffect(() => {
    const {
      data: {
        mainForms: { edges: mainForms },
        subForms: { edges: subForms },
      },
    } = props

    const finalData = mainForms.map(
      ({ node: { frontmatter: nodeData, id } }) => {
        let item = { ...nodeData, id, subForms: [] }
        item.subForms = filter(
          subForms,
          ({ node: { frontmatter: subNodeData, id } }) =>
            subNodeData.path.indexOf(nodeData.path) > -1
        )
        return item
      }
    )
    setData(finalData)
    setFilteredData(finalData)
  }, [])

  const filterData = query => {
    // setLoading(true)

    if (isEmpty(query)) {
      setFilteredData(data)
      setLoading(false)
      return
    }

    let filterData = []
    for (let item of data) {
      const nestedData = item.subForms.filter(
        ({ node: { frontmatter } }) =>
          frontmatter.path.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          frontmatter.title.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          frontmatter.categoryID
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
      )

      if (
        nestedData.length ||
        item.categoryName.toLowerCase().indexOf(query.toLowerCase()) > -1
      )
        filterData.push({ ...item, subForms: nestedData })
      else if (
        !item.categoryName.toLowerCase().indexOf(query.toLowerCase()) > -1 &&
        nestedData.length
      )
        filterData.push({ ...item, subForms: nestedData })
    }

    setFilteredData(filterData)
    setLoading(false)
  }

  const Lines = ({ icon, path, title, copyable = false }) => (
    <div className="just-content-center">
      <FontAwesomeIcon icon={icon} />
      {"  "}
      {path ? <Link to={path}>{title}</Link> : title}
    </div>
  )

  return (
    <Layout>
      <SEO title="All Forms Directory" description="All pages" />
      <Container className="mt-50 mb-5 pt-5">
        <h2 className="text-center">All Services</h2>
        <Row className="d-flex justify-content-center align-items-center my-3 mx-1">
          <Col xs={10}>
            <Input onChange={hadleInput} />
          </Col>
          <Col>
            {loading && (
              <span
                className="spinner-border spinner-border-sm ml-3"
                role="status"
              />
            )}
          </Col>
        </Row>
        <Row className="m-0">
          {filteredData.map(
            ({ id, categoryName, path, title, subForms }, index) => (
              <Col xs={12} className="" key={id}>
                <h4 className="font-weight-bolder text-secondary">
                  {getHighlightedText(categoryName)}:
                </h4>
                <div className="d-flex flex-column">
                  <Lines path={path} icon={faHeading} title={title} />
                  <Lines path={path} icon={faBook} title={path} />
                </div>

                <Row className="my-3">
                  {subForms.map(
                    ({
                      node: {
                        id,
                        frontmatter: { path, title, categoryID },
                      },
                    }) => (
                      <Col key={id} xs={11} md={5} className="card m-2 p-3">
                        <h5 className=" text-secondary category-title">
                          {" "}
                          <Link to={path} target="blank">
                            <span className="font-weight-bolder text-secondary">
                              {getHighlightedText(title)}
                            </span>
                          </Link>
                        </h5>

                        <div className="d-flex flex-column category-body">
                          <Lines
                            path={null}
                            icon={faFingerprint}
                            title={getHighlightedText(categoryID)}
                            copyable
                          />
                          <Lines
                            path={path}
                            icon={faBook}
                            title={getHighlightedText(path)}
                          />
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
            )
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    mainForms: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" } }
      sort: { order: ASC, fields: [frontmatter___path] }
    ) {
      edges {
        node {
          id
          frontmatter {
            categoryID
            categoryName
            path
            title
          }
        }
      }
    }
    subForms: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/sub-markdown-pages/*.md" } }
      sort: { order: ASC, fields: [frontmatter___path] }
    ) {
      edges {
        node {
          id
          frontmatter {
            categoryID
            categoryName
            path
            title
          }
        }
      }
    }
  }
`
